import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import paymentsService from './paymentsService'
import { updateUserCardDetails } from '../../features/auth/authSlice'

const initialState = {
  paymentStatus: null,
  errorMessage: null,
  isLoading: false,
  subscriptionIsProcessing: false,
  isSuccess: false,
  paymentDetails: null,
  subscriptionDetails: null,
  isError: null,
  products: null,
  cardError: null,
  TEDSPackages: [],
}

// Async thunk for processing payment
export const processPayment = createAsyncThunk('payment/processPayment', async (paymentData, { rejectWithValue }) => {
  console.log('Processing payment...')
  console.log(paymentData, 'did it work?')
  try {
    const response = await paymentsService.processPayment(paymentData)
    return response
  } catch (error) {
    return rejectWithValue(error.response?.data || error.message)
  }
})

// Add new async thunk for resubscription
export const processResubscription = createAsyncThunk('payment/processResubscription', async (paymentData, { rejectWithValue }) => {
  console.log('Processing resubscription...')
  try {
    const response = await paymentsService.processResubscription(paymentData)
    return response
  } catch (error) {
    return rejectWithValue(error.response?.data || error.message)
  }
})

// *get available products
export const getProducts = createAsyncThunk('payment/getProducts', async (_, thunkAPI) => {
  try {
    return await paymentsService.getProducts()
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// Add new thunk
export const updateCustomerCard = createAsyncThunk('payments/updateCard', async ({ customerId, cardNonce, verificationToken }, thunkAPI) => {
  console.log('update card called in slice')
  try {
    const token = thunkAPI.getState().auth.user.token
    const response = await paymentsService.updateCustomerCard(
      {
        customerId,
        cardNonce,
        verificationToken,
      },
      token
    )

    // Dispatch action to update auth state with new card details
    thunkAPI.dispatch(updateUserCardDetails(response.data))

    return response
  } catch (error) {
    const message = error.response?.data?.message || error.message
    return thunkAPI.rejectWithValue(message)
  }
})

// *get teds packages
export const getTEDSPackages = createAsyncThunk('payments/getTEDSPackages', async (_, thunkAPI) => {
  try {
    return await paymentsService.getTEDSPackages()
  } catch (error) {
    const message = error.response?.data?.message || error.message
    return thunkAPI.rejectWithValue(message)
  }
})

export const processTEDSPayment = createAsyncThunk('payments/processTEDSPayment', async (paymentData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await paymentsService.processTEDSPayment(paymentData, token)
  } catch (error) {
    const message = error.response?.data?.message || error.message
    return thunkAPI.rejectWithValue(message)
  }
})

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    paymentSuccess: (state) => {
      state.paymentStatus = 'success'
    },
    paymentFailure: (state, action) => {
      state.paymentStatus = 'failure'
      state.errorMessage = action.payload
    },
    resetPaymentStatus: (state) => {
      state.paymentStatus = null
      state.errorMessage = null
      state.subscriptionDetails = null
    },
    reset: (state) => {
      state.isSuccess = false
      state.isError = null
      state.message = null
      state.isLoading = false
      state.cardError = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(processPayment.pending, (state) => {
        state.isLoading = true
        state.isError = null
        state.isSuccess = false
      })
      .addCase(processPayment.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.paymentDetails = action.payload.payment
        state.subscriptionDetails = action.payload.subscription
      })
      .addCase(processPayment.rejected, (state, action) => {
        console.log(action.payload, 'error in payment')
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
    builder.addCase(getProducts.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.isLoading = false

      state.products = action.payload // set the products to the fetched data
    })
    builder.addCase(getProducts.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    // Add new cases for resubscription
    builder
      .addCase(processResubscription.pending, (state) => {
        state.isLoading = true
        state.isError = null
        state.message = ''
        state.isSuccess = false
      })
      .addCase(processResubscription.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.paymentDetails = action.payload.payment
        state.subscriptionDetails = action.payload.subscription
      })
      .addCase(processResubscription.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = action.payload.message
      })
    builder
      .addCase(updateCustomerCard.pending, (state) => {
        state.isLoading = true
        state.isError = false
        state.isSuccess = false
        state.message = ''
        state.cardError = null
      })
      .addCase(updateCustomerCard.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload.message
        state.paymentDetails = action.payload.data
        state.cardError = null
      })
      .addCase(updateCustomerCard.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = action.payload.message
        state.cardError = action.payload
      })
    builder.addCase(getTEDSPackages.pending, (state) => {
      state.isLoading = true
      state.isError = false
      state.message = ''
    })
    builder.addCase(getTEDSPackages.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.TEDSPackages = action.payload
    })
    builder.addCase(getTEDSPackages.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder
      .addCase(processTEDSPayment.pending, (state) => {
        state.isLoading = true
        state.isError = false
        state.message = ''
      })
      .addCase(processTEDSPayment.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.paymentDetails = action.payload
      })
      .addCase(processTEDSPayment.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
  },
})

export const { paymentSuccess, paymentFailure, resetPaymentStatus, clearSubscriptionDetails, reset } = paymentsSlice.actions
export default paymentsSlice.reducer
