import api from '../../api'

const { REACT_APP_SERVER_URL } = process.env

const PAYMENTS_URL = `${REACT_APP_SERVER_URL}/api/payments`

// Process payment using the token from Square
const processPayment = async (paymentData) => {
  try {
    const response = await api.post(PAYMENTS_URL + '/process-payment', paymentData)
    return response.data
  } catch (error) {
    console.error('Error in processPayment:', error)
    throw error.response?.data || error
  }
}

const getProducts = async () => {
  try {
    console.log('Getting products')
    const products = await api.get(`${PAYMENTS_URL}/products`)
    console.log('products returned')
    return products.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 500) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// Process resubscription using the token from Square
const processResubscription = async (paymentData) => {
  console.log('Resubscription data being sent:', paymentData)
  try {
    const response = await api.post(PAYMENTS_URL + '/process-resubscription', paymentData)
    return response.data
  } catch (error) {
    console.error('Error in processResubscription:', error)
    throw error
  }
}

const updateCustomerCard = async (cardData, token) => {
  console.log('update card called in service')
  try {
    const response = await api.put(`${PAYMENTS_URL}/update-card`, cardData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (error) {
    throw (
      error.response?.data || {
        success: false,
        message: 'An unexpected error occurred',
        error: {
          code: 'UNKNOWN_ERROR',
          category: 'SYSTEM_ERROR',
          detail: error.message,
          field: null,
        },
      }
    )
  }
}

const getTEDSPackages = async () => {
  try {
    const products = await api.get(`${PAYMENTS_URL}/get-teds-packages`)
    return products.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 500) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const processTEDSPayment = async (paymentData, token) => {
  try {
    const response = await api.post(`${PAYMENTS_URL}/process-teds-payment`, paymentData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (error) {
    console.error('Error in processTEDSPayment:', error)
    if (error.response?.data) {
      throw error.response.data
    }
    throw new Error('Failed to process TEDS payment')
  }
}

const paymentsService = {
  getProducts,
  processPayment,
  processResubscription,
  updateCustomerCard,
  getTEDSPackages,
  processTEDSPayment,
}

export default paymentsService
