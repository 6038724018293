import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { cancelSubscription } from '../features/subscription/subscriptionSlice'
import { getUserDetails, updateSubscriptionStatus } from '../features/auth/authSlice'
import { getProducts, processResubscription } from '../features/payments/paymentsSlice'
import { BsThreeDotsVertical, BsCreditCard, BsXCircle, BsArrowRepeat } from 'react-icons/bs'
import { Modal } from './Modal'
import UpdatePaymentModal from './UpdatePaymentModal'
import SubscriptionPaymentForm from './SubscriptionPaymentForm'

const Subscription = () => {
  const dispatch = useDispatch()
  const { userDetails, user } = useSelector((state) => state.auth)
  const { products } = useSelector((state) => state.payments)
  const { subscriptionCancelled } = useSelector((state) => state.subscription)
  const [isManageModalOpen, setIsManageModalOpen] = useState(false)
  const [isCancelling, setIsCancelling] = useState(false)
  const [showUpdatePaymentModal, setShowUpdatePaymentModal] = useState(false)
  const [isPaymentModalLoading, setIsPaymentModalLoading] = useState(true)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [showResubscribeModal, setShowResubscribeModal] = useState(false)
  const [showPaymentForm, setShowPaymentForm] = useState(false)
  const [selectedVariation, setSelectedVariation] = useState(null)
  const [paymentSuccess, setPaymentSuccess] = useState(false)

  console.log(paymentSuccess, 'payment success')

  useEffect(() => {
    dispatch(getProducts())
  }, [dispatch])

  if (!userDetails) {
    return (
      <div className='flex flex-col w-full justify-between items-left border p-4 rounded-md mt-8 bg-gray-50 mb-20'>
        <div className='flex justify-center items-center p-8'>
          <span className='loading loading-spinner loading-lg'></span>
        </div>
      </div>
    )
  }

  const handlePaymentSuccess = async (paymentData) => {
    console.log('processing resubscription')
    if (user && paymentData) {
      try {
        const response = await dispatch(
          processResubscription({
            paymentToken: paymentData,
            currency: user.subscription.currency.toUpperCase(),
            email: userDetails.email,
          })
        ).unwrap()

        dispatch(updateSubscriptionStatus(response.subscription))
        setPaymentSuccess(true)

        setTimeout(() => {
          setShowResubscribeModal(false)
          setShowPaymentForm(false)
        }, 3000)

        return true
      } catch (error) {
        console.error('Resubscription error:', error)
        toast.error('Failed to reactivate subscription. Please try again.')
        throw error
      }
    }
  }

  const getResubscribeProducts = () => {
    return products.filter((product) => {
      // Filter for products with "Without Trial" in the name
      return product.presentAtAllLocations !== false && product.subscriptionPlanData?.name?.includes('Without Trial')
    })
  }

  const handleSubscribe = (productId) => {
    if (productId) {
      handlePaymentSuccess(productId)
    } else {
      setShowResubscribeModal(true)
    }
  }

  const handleUpdatePayment = () => {
    setIsManageModalOpen(false)
    setShowUpdatePaymentModal(true)
  }

  const handleCancelSubscription = async () => {
    try {
      setIsCancelling(true)
      await dispatch(cancelSubscription(userDetails.squareCustomerID)).unwrap()
      await dispatch(getUserDetails()).unwrap()

      // Auto-close after 3 seconds
      setTimeout(() => {
        setIsConfirmModalOpen(false)
      }, 3000)
    } catch (error) {
      toast.error(error.message || 'Failed to cancel subscription')
    } finally {
      setIsCancelling(false)
    }
  }

  return (
    <div className='flex flex-col w-full justify-between items-left border p-4 rounded-md mt-8 bg-gray-50 mb-20'>
      <div className='flex justify-between items-center'>
        <h1 className='font-bold text-xl md:text-2xl md:text-left'>Manage Subscription</h1>
        <div className='flex'>
          {userDetails && (userDetails.cancelAtPeriodEnd || userDetails.subscriptionStatus === 'canceled') && (
            <div className='tooltip' data-tip={`Cancels on ${new Date(userDetails.endDate).toLocaleDateString()}`}>
              <p className='mr-3 font-bold cursor-help text-gray-500'>Cancelling</p>
            </div>
          )}

          {userDetails && userDetails.subscriptionStatus === 'trialing' ? (
            <div className='flex justify-between items-center'>
              <div className='flex items-center'>
                <div className={`badge text-white ${userDetails.subscriptionIsActive ? 'bg-green-600' : 'bg-red-400'} border-none`}>
                  {userDetails.subscriptionIsActive ? 'Free trial' : 'Inactive'}
                </div>
              </div>
            </div>
          ) : userDetails.subscriptionStatus === 'payment_failed' ? (
            <div className='flex items-center'>
              <div className='badge badge-error text-white border-none'>Payment Failed</div>
            </div>
          ) : (
            <div className='flex justify-between items-center'>
              <div className='flex items-center'>
                <div className={`badge text-white ${userDetails.subscriptionIsActive ? 'bg-success' : 'bg-error'} border-none`}>
                  {userDetails.subscriptionIsActive ? 'Active' : 'Unsubscribed'}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <>
        <div className='divider'></div>

        {!userDetails.subscriptionIsActive && userDetails.subscriptionStatus !== 'trialing' ? (
          // Unsubscribed state UI
          <div className='flex flex-col lg:flex-row justify-evenly mt-4'>
            <div className='card w-full lg:full bg-base-100 shadow-xl lg:mx-5 mb-4 lg:mb-0'>
              <div className='card-body'>
                <div className='flex justify-between w-full'>
                  <div className='flex font-bold text-xl md:text-2xl'>Previous Subscription Details</div>
                </div>

                {/* Show previous plan details */}
                <div className='flex justify-between w-full mt-4'>
                  <div>
                    <p className='font-bold mb-3'>Previous Plan</p>
                    <p className='text-gray-600'>{userDetails.plan || 'N/A'}</p>
                  </div>
                  <div>
                    <p className='font-bold mb-3'>Last Payment</p>
                    <p className='text-gray-600'>{userDetails.price ? `£${(Number(userDetails.price) / 100).toFixed(2)}` : 'N/A'}</p>
                  </div>
                </div>

                {/* Resubscribe CTA */}
                <div className='mt-6 p-4 bg-blue-50 rounded-lg'>
                  {!paymentSuccess && (
                    <>
                      <h3 className='font-bold text-lg mb-2'>Ready to reactivate your subscription?</h3>
                      <p className='text-gray-600 mb-4'>Resubscribe now to regain access to all features and continue managing your campaigns.</p>
                      <button onClick={() => handleSubscribe()} className='btn btn-primary btn-block'>
                        Resubscribe Now
                      </button>
                    </>
                  )}
                  {paymentSuccess && <p>Refresh the page in a couple of minutes to update your subscription details</p>}
                </div>
              </div>
            </div>
          </div>
        ) : (
          // Active subscription UI (existing code)
          <div className='flex flex-col lg:flex-row justify-evenly mt-4'>
            {/* Billing & Payment*/}
            <div className='card w-full lg:full bg-base-100 shadow-xl lg:mx-5 mb-4 lg:mb-0'>
              <div className='card-body'>
                <div className='flex justify-between w-full'>
                  <div className='flex font-bold text-xl md:text-2xl'>Billing & Payment</div>
                  <div className='dropdown dropdown-end'>
                    <label tabIndex={0} className='btn btn-ghost btn-sm'>
                      <BsThreeDotsVertical className='h-5 w-5' />
                      Manage
                    </label>
                    <ul tabIndex={0} className='dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52'>
                      {userDetails.subscriptionIsActive ? (
                        <>
                          <li>
                            <button onClick={() => setIsManageModalOpen(true)} className='flex items-center gap-2'>
                              <BsCreditCard /> Update Payment
                            </button>
                          </li>
                          {!userDetails.cancelAtPeriodEnd && (
                            <li>
                              <button onClick={() => setIsConfirmModalOpen(true)} className='flex items-center gap-2 text-error'>
                                <BsXCircle /> Cancel Subscription
                              </button>
                            </li>
                          )}
                        </>
                      ) : (
                        <>
                          {!userDetails.subscriptionIsActive && !paymentSuccess ? (
                            <li>
                              <button onClick={() => handleSubscribe()} className='flex items-center gap-2 text-success'>
                                <BsArrowRepeat /> Resubscribe
                              </button>
                            </li>
                          ) : null}
                        </>
                      )}
                    </ul>
                  </div>
                </div>
                <div className='flex justify-between w-full mt-4'>
                  <div className=''>
                    <p className='font-bold mb-3 md:text-base '>Price</p>
                  </div>
                  <div>
                    <p>
                      {userDetails.price
                        ? `£${(Number(userDetails.price) / 100).toFixed(2)} / ${userDetails.plan === 'monthly' ? 'Mo' : 'Yr'}`
                        : 'nope'}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between w-full'>
                  <div>
                    <p className='font-bold'>Billing Period</p>
                  </div>
                  <div>
                    {' '}
                    <p>{userDetails.plan ? userDetails.plan.charAt(0).toUpperCase() + userDetails.plan.slice(1) : 'No Valid'} Subscription</p>
                  </div>
                </div>
                <div className='flex justify-between w-full mt-4'>
                  <div>
                    <p className='font-bold'>
                      {userDetails.cancelAtPeriodEnd || userDetails.subscriptionStatus === 'canceled'
                        ? 'Cancelling on'
                        : userDetails.subscriptionStatus === 'trialing'
                        ? 'Trial ends on'
                        : 'Renewing on'}
                    </p>
                  </div>
                  <div>
                    <p className='text-gray-400'>
                      {userDetails.cancelAtPeriodEnd || userDetails.subscriptionStatus === 'canceled'
                        ? `Cancels on ${new Date(userDetails.endDate).toLocaleDateString()}`
                        : userDetails.subscriptionStatus === 'trialing'
                        ? `${new Date(userDetails.endDate).toLocaleDateString()}`
                        : `${new Date(userDetails.endDate).toLocaleDateString()}`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>

      {userDetails && (
        <Modal isOpen={isManageModalOpen} onClose={() => setIsManageModalOpen(false)}>
          <div className='p-6'>
            <h2 className='text-2xl font-bold mb-6'>Manage Your Subscription</h2>

            <div className='space-y-6'>
              {/* Payment Method Section */}
              <div className='border rounded-lg p-4'>
                <h3 className='text-lg font-semibold mb-4'>Payment Method</h3>
                <div className='flex justify-between items-center'>
                  <div className='flex items-center gap-2'>
                    <img
                      src={`/images/card-icons/${user.subscription.cardDetails.brand.toLowerCase()}.png`}
                      alt={user.subscription.cardDetails.brand}
                      className='h-6'
                    />
                    <span>•••• {user.subscription.cardDetails.last4 || 'Not available'}</span>
                  </div>
                  <button onClick={handleUpdatePayment} className='btn btn-sm btn-ghost'>
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {showUpdatePaymentModal && (
        <UpdatePaymentModal
          isOpen={showUpdatePaymentModal}
          onClose={() => setShowUpdatePaymentModal(false)}
          customerId={userDetails.squareCustomerID}
          isLoading={isPaymentModalLoading}
          setIsLoading={setIsPaymentModalLoading}
        />
      )}

      {isConfirmModalOpen && (
        <Modal isOpen={isConfirmModalOpen} onClose={() => setIsConfirmModalOpen(false)}>
          <div className='p-6'>
            {subscriptionCancelled ? (
              <div className='text-center py-8'>
                <div className='mb-4'>
                  <svg className='mx-auto h-12 w-12 text-green-500' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M5 13l4 4L19 7' />
                  </svg>
                </div>
                <h3 className='text-2xl font-bold text-green-600 mb-2'>Subscription Cancelled!</h3>
                <p className='text-gray-600'>Your subscription will remain active until the end of your billing period.</p>
                <p className='text-sm text-gray-500 mt-2'>This window will close automatically...</p>
              </div>
            ) : (
              <>
                <h3 className='text-lg font-bold mb-4'>Cancel Subscription</h3>
                <div className='space-y-4'>
                  <p>
                    Your subscription will remain active until the end of your current billing period on{' '}
                    <span className='font-medium'>{new Date(userDetails.endDate).toLocaleDateString()}</span>.
                  </p>
                  <p>After this date, your subscription will automatically cancel and you'll lose access to all key features</p>
                  <p className='text-sm text-gray-500'>You can reactivate your subscription at any time before the cancellation date.</p>
                </div>
                <div className='flex justify-end gap-2 mt-6'>
                  <button className='btn btn-ghost' onClick={() => setIsConfirmModalOpen(false)}>
                    Keep My Subscription
                  </button>
                  <button className='btn btn-error' disabled={isCancelling} onClick={handleCancelSubscription}>
                    {isCancelling ? (
                      <>
                        <span className='loading loading-spinner loading-sm'></span>
                        Processing...
                      </>
                    ) : (
                      'Confirm Cancellation'
                    )}
                  </button>
                </div>
              </>
            )}
          </div>
        </Modal>
      )}

      {showResubscribeModal && (
        <Modal
          isOpen={showResubscribeModal}
          onClose={() => {
            setShowResubscribeModal(false)
            setShowPaymentForm(false)
            setPaymentSuccess(false)
            // Clean up any existing Square iframe when closing
            const existingIframe = document.querySelector('iframe[name^="square-"]')
            if (existingIframe) {
              existingIframe.remove()
            }
          }}>
          <div className='p-6' id='subscription-modal-content'>
            {paymentSuccess ? (
              <div className='text-center py-8'>
                <div className='mb-4'>
                  <svg className='mx-auto h-12 w-12 text-green-500' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M5 13l4 4L19 7' />
                  </svg>
                </div>
                <h3 className='text-2xl font-bold text-green-600 mb-2'>Payment Successful!</h3>
                <p className='text-gray-600'>Your subscription has been reactivated successfully.</p>
                <p className='text-sm text-gray-500 mt-2'>This window will close automatically...</p>
              </div>
            ) : showPaymentForm ? (
              <SubscriptionPaymentForm
                product={getResubscribeProducts().find((p) =>
                  p.subscriptionPlanData?.subscriptionPlanVariations?.some((v) => v.id === selectedVariation.id)
                )}
                selectedVariation={selectedVariation}
                onPaymentSuccess={handlePaymentSuccess}
                onCancel={() => {
                  setShowPaymentForm(false)
                  setSelectedVariation(null)
                  // Clean up Square iframe when canceling
                  const existingIframe = document.querySelector('iframe[name^="square-"]')
                  if (existingIframe) {
                    existingIframe.remove()
                  }
                }}
                modalContentId='subscription-modal-content'
              />
            ) : (
              <>
                <h2 className='text-2xl font-bold mb-6'>Choose a Subscription Plan</h2>
                <div className='grid gap-4'>
                  {getResubscribeProducts().map((product) => {
                    if (!product.subscriptionPlanData?.subscriptionPlanVariations) return null

                    return product.subscriptionPlanData.subscriptionPlanVariations.map((variation) => {
                      const variationData = variation.subscriptionPlanVariationData
                      const pricingPhase = variationData.phases.find((phase) => phase.ordinal === '0')
                      const phaseCadence = pricingPhase?.cadence
                      const priceAmount = pricingPhase?.pricing?.priceMoney?.amount

                      return (
                        <div key={variation.id} className='border rounded-lg p-4 hover:border-primary transition-colors'>
                          <div className='flex flex-col space-y-4'>
                            <div>
                              <h3 className='text-xl font-bold'>{product.subscriptionPlanData.name}</h3>
                              <p className='text-gray-600'>{variationData.name}</p>
                            </div>

                            <div className='flex items-baseline'>
                              <span className='text-3xl font-bold'>£{(parseInt(priceAmount) / 100).toFixed(2)}</span>
                              <span className='text-gray-600 ml-1'>/{phaseCadence.toLowerCase() === 'monthly' ? 'month' : 'year'}</span>
                            </div>

                            <button
                              onClick={() => {
                                setSelectedVariation(variation)
                                setShowPaymentForm(true)
                              }}
                              className='btn btn-primary w-full'>
                              Select Plan
                            </button>
                          </div>
                        </div>
                      )
                    })
                  })}
                </div>
              </>
            )}
          </div>
        </Modal>
      )}

      {userDetails.subscriptionStatus === 'payment_failed' && (
        <div className='mt-4 p-4 bg-red-50 border border-red-100 rounded-lg'>
          <h3 className='font-semibold text-red-700 mb-2'>We noticed an issue with your last payment</h3>
          <p className='text-gray-700 mb-2'>
            Don't worry! We understand these things happen. We've provided you with a 5-day grace period to ensure you don't experience any
            interruption in service.
          </p>
          <p className='text-gray-600 text-sm'>
            To keep your account active, please resubscribe before {new Date(userDetails.gracePeriodEnd).toLocaleDateString()}. After this date, your
            account access will be temporarily suspended until a new subscription is set up.
          </p>
          <button onClick={() => handleSubscribe()} className='btn btn-error btn-sm mt-3'>
            Resubscribe Now
          </button>
        </div>
      )}
    </div>
  )
}

export default Subscription
