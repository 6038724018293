export const Modal = ({ isOpen, onClose, children, title }) => {
  if (!isOpen) return null

  return (
    <div className='fixed inset-0 z-50 overflow-y-auto custom-zindex'>
      <div className='flex items-center justify-center min-h-screen p-4'>
        <div className='fixed inset-0 bg-black opacity-30' onClick={onClose}></div>
        <div className='relative bg-white rounded-lg max-w-lg w-full max-h-[90vh]'>
          <button
            onClick={onClose}
            className='btn btn-sm btn-circle sticky top-2 float-right mr-2 z-10 bg-black text-white hover:bg-gray-800 transition-transform scale-90 hover:scale-110'>
            ✕
          </button>
          <div className='overflow-y-auto max-h-[90vh]'>
            <div className='p-6'>
              {title && <h2 className='font-bold text-2xl mb-4'>{title}</h2>}
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
