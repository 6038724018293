import { useState, useEffect, useRef, createRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserPassword, updateSecurityCode, checkSecurityCodeStatus } from '../features/auth/authSlice'
import toast from 'react-hot-toast'

const PasswordAndSecurity = () => {
  const dispatch = useDispatch()
  const { isLoading, isSecurityLoading, hasSecurityCode, user } = useSelector((state) => state.auth)

  // Fetch security code status on component mount
  useEffect(() => {
    dispatch(checkSecurityCodeStatus())
  }, [dispatch])

  // Password states
  const [formData, setFormData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  })

  // Security code states
  const [securityCode, setSecurityCode] = useState('')
  console.log('🚀 ~ PasswordAndSecurity ~ securityCode:', securityCode)
  const [confirmCode, setConfirmCode] = useState('')
  const [currentSecurityCode, setCurrentSecurityCode] = useState('')
  const [currentSecurityDigits, setCurrentSecurityDigits] = useState(['', '', '', '', '', ''])
  const [newSecurityDigits, setNewSecurityDigits] = useState(['', '', '', '', '', ''])
  const [confirmSecurityDigits, setConfirmSecurityDigits] = useState(['', '', '', '', '', ''])
  const currentDigitRefs = useRef([...Array(6)].map(() => createRef()))
  const newDigitRefs = useRef([...Array(6)].map(() => createRef()))
  const confirmDigitRefs = useRef([...Array(6)].map(() => createRef()))

  const handlePasswordChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const handlePasswordSubmit = (e) => {
    e.preventDefault()

    if (formData.newPassword !== formData.confirmNewPassword) {
      toast.error('The new password and confirmation do not match.')
      return
    }

    dispatch(updateUserPassword(formData))
    setFormData({
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    })
  }

  const handleDigitChange = (index, value, digits, setDigits, refs) => {
    if (!/^\d*$/.test(value)) return

    const newDigits = [...digits]
    newDigits[index] = value
    setDigits(newDigits)

    // Auto-focus next input
    if (value && index < 5) {
      refs.current[index + 1].current.focus()
    }
  }

  const handleKeyDown = (index, e, refs) => {
    if (e.key === 'Backspace' && !e.target.value && index > 0) {
      refs.current[index - 1].current.focus()
    }
  }

  const handleSecuritySubmit = async (e) => {
    e.preventDefault()

    const newCode = newSecurityDigits.join('')
    const confirmCode = confirmSecurityDigits.join('')
    const currentCode = currentSecurityDigits.join('')

    if (newCode.length !== 6) {
      toast.error('Security code must be 6 digits')
      return
    }

    if (newCode !== confirmCode) {
      toast.error('Codes do not match')
      return
    }

    const resultAction = await dispatch(
      updateSecurityCode({
        currentCode: hasSecurityCode ? currentCode : null,
        newCode: newCode,
      })
    )

    if (resultAction.meta.requestStatus === 'fulfilled') {
      setCurrentSecurityDigits(['', '', '', '', '', ''])
      setNewSecurityDigits(['', '', '', '', '', ''])
      setConfirmSecurityDigits(['', '', '', '', '', ''])
      dispatch(checkSecurityCodeStatus())
    }
  }

  return (
    <div className='flex flex-col w-full justify-between items-left border p-4 rounded-md mt-8 bg-gray-50'>
      <h1 className='font-bold text-xl md:text-2xl md:text-left'>Password & Security Settings</h1>
      <div className='divider'></div>

      {/* Password Update Section */}
      <div className='mb-8'>
        <h2 className='text-lg font-semibold mb-4'>Update Password</h2>
        <form onSubmit={handlePasswordSubmit}>
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2'>
            <label className='form-label'>CURRENT PASSWORD</label>
            <input
              type='password'
              className='form-input-profile'
              name='currentPassword'
              value={formData.currentPassword}
              onChange={handlePasswordChange}
              placeholder='Enter Current Password'
            />
          </div>

          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2'>
            <label className='form-label'>NEW PASSWORD</label>
            <input
              type='password'
              className='form-input-profile'
              name='newPassword'
              value={formData.newPassword}
              onChange={handlePasswordChange}
              placeholder='Enter New Password'
            />
          </div>

          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-4'>
            <label className='form-label'>CONFIRM PASSWORD</label>
            <input
              type='password'
              className='form-input-profile'
              name='confirmNewPassword'
              value={formData.confirmNewPassword}
              onChange={handlePasswordChange}
              placeholder='Confirm New Password'
            />
          </div>

          <button className='btn btn-sm flex' disabled={isLoading}>
            {isLoading ? (
              <>
                <img src='/images/loadingSpinner.svg' alt='Loading...' />
                <span>Saving...</span>
              </>
            ) : (
              <span>Update Password</span>
            )}
          </button>
        </form>
      </div>

      {/* Security Code Section */}
      <div className='mb-8'>
        <h2 className='text-lg font-semibold mb-4'>TEDS Security Code</h2>

        {/* Show info alert when no security code is set */}
        {!hasSecurityCode && (
          <div className='alert alert-info mb-4 shadow-lg'>
            <div>
              <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' className='stroke-current flex-shrink-0 w-6 h-6'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'></path>
              </svg>
              <div>
                <h3 className='font-bold'>Security Recommendation</h3>
                <p className='text-sm'>
                  Setting up a security code adds an extra layer of protection when exchanging TEDS for cash. We recommend setting this up for
                  enhanced account security.
                </p>
              </div>
            </div>
          </div>
        )}

        <form onSubmit={handleSecuritySubmit}>
          {hasSecurityCode && (
            <div className='mb-4'>
              <label className='form-label block mb-2'>CURRENT CODE</label>
              <div className='flex gap-2 justify-start'>
                {currentSecurityDigits.map((digit, index) => (
                  <input
                    key={index}
                    type='password'
                    ref={currentDigitRefs.current[index]}
                    value={digit}
                    onChange={(e) => handleDigitChange(index, e.target.value, currentSecurityDigits, setCurrentSecurityDigits, currentDigitRefs)}
                    onKeyDown={(e) => handleKeyDown(index, e, currentDigitRefs)}
                    maxLength={1}
                    className='w-12 h-12 text-center input input-bordered'
                    inputMode='numeric'
                    pattern='\d*'
                    autoComplete='off'
                    required
                  />
                ))}
              </div>
            </div>
          )}

          <div className='mb-4'>
            <label className='form-label block mb-2'>NEW SECURITY CODE</label>
            <div className='flex gap-2 justify-start'>
              {newSecurityDigits.map((digit, index) => (
                <input
                  key={index}
                  type='password'
                  ref={newDigitRefs.current[index]}
                  value={digit}
                  onChange={(e) => handleDigitChange(index, e.target.value, newSecurityDigits, setNewSecurityDigits, newDigitRefs)}
                  onKeyDown={(e) => handleKeyDown(index, e, newDigitRefs)}
                  maxLength={1}
                  className='w-12 h-12 text-center input input-bordered'
                  inputMode='numeric'
                  pattern='\d*'
                  autoComplete='off'
                  required
                />
              ))}
            </div>
          </div>

          <div className='mb-4'>
            <label className='form-label block mb-2'>CONFIRM NEW SECURITY CODE</label>
            <div className='flex gap-2 justify-start'>
              {confirmSecurityDigits.map((digit, index) => (
                <input
                  key={index}
                  type='password'
                  ref={confirmDigitRefs.current[index]}
                  value={digit}
                  onChange={(e) => handleDigitChange(index, e.target.value, confirmSecurityDigits, setConfirmSecurityDigits, confirmDigitRefs)}
                  onKeyDown={(e) => handleKeyDown(index, e, confirmDigitRefs)}
                  maxLength={1}
                  className='w-12 h-12 text-center input input-bordered'
                  inputMode='numeric'
                  pattern='\d*'
                  autoComplete='off'
                  required
                />
              ))}
            </div>
          </div>

          <p className='text-sm text-gray-600 mb-4'>
            This security code adds an extra layer of protection and will be required when withdrawing TEDS. Please keep this code safe - if you
            forget it, you will need to contact support to reset it.
          </p>

          <button className='btn btn-sm flex' disabled={isSecurityLoading}>
            {isSecurityLoading ? (
              <>
                <img src='/images/loadingSpinner.svg' alt='Loading...' />
                <span>Saving...</span>
              </>
            ) : (
              <span>Update Security Code</span>
            )}
          </button>
        </form>
      </div>
    </div>
  )
}

export default PasswordAndSecurity
