import { useState, useEffect, useRef } from 'react'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { useSelector, useDispatch } from 'react-redux'
import { fetchImage } from '../features/auth/authSlice'
import { reset, updateCampaignDetails, fetchCampaign } from '../features/campaign/campaignsSlice'
import Spinner from '../components/Spinner'
import { AiOutlineCloseCircle, AiOutlineInfoCircle } from 'react-icons/ai'
import BackButton from '../components/BackButton'
import IconSelectEdit from '../components/IconSelectEdit'
import { useParams } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

function EditCampaign() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const prevCampaignRef = useRef()
  const [formErrors, setFormErrors] = useState({})
  const { user, userDetails = {} } = useSelector((state) => state.auth)
  const { isLoading, isError, message, campaign, campaignUpdatedSuccess } = useSelector((state) => state.campaigns)
  const { campaignId } = useParams()
  const [isStartDateEditable, setIsStartDateEditable] = useState(false)
  const [previousTedsPerStamp, setPreviousTedsPerStamp] = useState(null)
  const [imageURL, setImageURL] = useState(null)

  const [includeEndDate, setIncludeEndDate] = useState(false)

  const [images, setImages] = useState({
    image: null,
  })

  const [formData, setFormData] = useState({
    campaignName: '',
    campaignType: 'Buy X, Get Y Free',
    spendPerStamp: '',
    rewardName: '',
    description: '',
    rules: [],
    status: 'active',
    startDate: new Date().toISOString().slice(0, 10),
    endDate: '',
    goal: '9',
    value: 'false',
    compulsory: 'false',
    referrerStampsRewarded: '1',
    referredFriendStampsRewarded: '1',
    enrolmentViaQr: true,
    enrolmentViaIntroduction: true,
    rewardNetCost: '',
    rewardRRP: '',
    image: null,
    icon: null,
    tedsActive: 'true',
    tedsPerStamp: '',
    allowAccumulation: true,
    resetAccumulationAfter: 0,
  })

  console.log('🚀 ~ EditCampaign ~ formData:', formData)

  const {
    campaignName,
    rewardName,
    description,
    status,
    startDate,
    endDate,
    goal,
    rules,
    value,
    compulsory,
    referrerStampsRewarded,
    referredFriendStampsRewarded,
    enrolmentViaQr,
    enrolmentViaIntroduction,
    rewardNetCost,
    rewardRRP,
    image,
    icon,
    tedsActive,
    tedsPerStamp,
    spendPerStamp,
    allowAccumulation,
    resetAccumulationAfter,
  } = formData

  const validateForm = () => {
    const errors = {}
    if (!campaignName.trim()) errors.campaignName = 'Campaign Name is required'
    if (!rewardName.trim()) errors.rewardName = 'Reward Name is required'

    // Validate startDate
    if (!startDate) errors.startDate = 'Start Date is required'

    // Validate endDate
    if (includeEndDate && !endDate) errors.endDate = 'End Date is required'
    else if (includeEndDate && new Date(endDate) <= new Date(startDate)) errors.endDate = 'End Date must be after Start Date'

    // Validate rewardNetCost
    if (!rewardNetCost) errors.rewardNetCost = 'Reward Net Cost is required'
    else if (isNaN(rewardNetCost) || rewardNetCost < 0) errors.rewardNetCost = 'Reward Net Cost must be a number'

    // Validate spendPerStamp when campaignType is 'Spend X Amount To Earn a Stamp'
    if (formData.campaignType === 'Spend X Amount To Earn a Stamp' && (!spendPerStamp || isNaN(spendPerStamp) || spendPerStamp <= 0)) {
      errors.spendPerStamp = 'Amount to Spend Per Stamp is required and must be a positive number'
    }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  useEffect(() => {
    prevCampaignRef.current = campaign
  })

  const prevCampaign = prevCampaignRef.current

  useEffect(() => {
    if (image) {
      dispatch(fetchImage(image)).then((res) => {
        setImages((prevState) => ({
          ...prevState,
          image: res.payload,
        }))
      })
    }
  }, [dispatch, image])

  useEffect(() => {
    dispatch(fetchCampaign(campaignId))
  }, [dispatch, campaignId])

  useEffect(() => {
    const normalizeCampaignData = (campaignData) => {
      // Pick only the keys that exist in your formData
      const normalizedData = _.pick(campaignData, Object.keys(formData))
      normalizedData.value = normalizedData.value?.toString() || 'false'
      // Format the dates and handle null values
      normalizedData.startDate = normalizedData.startDate ? new Date(normalizedData.startDate).toISOString().split('T')[0] : ''
      normalizedData.endDate = normalizedData.endDate ? new Date(normalizedData.endDate).toISOString().split('T')[0] : ''

      return normalizedData
    }

    if (campaign && campaign._id && !_.isEqual(prevCampaign, normalizeCampaignData(campaign))) {
      const normalizedData = normalizeCampaignData(campaign)
      // Override initial state with fetched data
      setFormData((prevFormData) => ({ ...prevFormData, ...normalizedData }))

      // Check if the startDate is in the future
      const campaignStartDate = new Date(normalizedData.startDate)
      const today = new Date()
      today.setHours(0, 0, 0, 0) // Reset hours, minutes, seconds, and milliseconds

      setIsStartDateEditable(campaignStartDate > today)

      // Set includeEndDate based on whether endDate is present in the fetched data
      setIncludeEndDate(!!normalizedData.endDate)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign])

  useEffect(() => {
    if (isError) {
      toast.error(message.message)
      dispatch(reset())
    }

    if (campaignUpdatedSuccess) {
      toast.success('Campaign updated successfully')
      dispatch(reset())
      navigate('/campaigns')
    }
  }, [isError, campaignUpdatedSuccess, user, message, navigate, dispatch])

  // Modify the onChange function to handle tedsActive changes
  const onChange = (e) => {
    const { name, value } = e.target
    setFormData((prevState) => {
      let newValue = value

      // Convert 'true' and 'false' strings to boolean values
      if (value === 'true' || value === 'false') {
        newValue = value === 'true'
      }

      // Convert numeric strings to numbers for certain fields
      if (['goal', 'referrerStampsRewarded', 'referredFriendStampsRewarded'].includes(name)) {
        newValue = value === '' ? '' : Number(value)
      }

      // Special handling for resetAccumulationAfter
      if (name === 'resetAccumulationAfter') {
        newValue = value === '' ? 0 : Number(value)
      }

      // Handle resetAccumulationAfter when allowAccumulation changes
      if (name === 'allowAccumulation') {
        return {
          ...prevState,
          [name]: newValue,
          resetAccumulationAfter: newValue ? prevState.resetAccumulationAfter || 0 : 0,
        }
      }

      // For other fields, just update normally
      return { ...prevState, [name]: newValue }
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (!validateForm()) {
      return
    }
    const campaignData = new FormData()
    campaignData.append('campaignName', campaignName)
    campaignData.append('campaignId', campaignId)
    campaignData.append('rewardName', rewardName)
    campaignData.append('description', description)
    campaignData.append('status', status)
    campaignData.append('startDate', startDate)
    campaignData.append('endDate', endDate)
    campaignData.append('goal', goal)
    campaignData.append('value', value)
    campaignData.append('compulsory', compulsory)
    campaignData.append('referrerStampsRewarded', referrerStampsRewarded)
    campaignData.append('referredFriendStampsRewarded', referredFriendStampsRewarded)
    campaignData.append('enrolmentViaQr', enrolmentViaQr)
    campaignData.append('enrolmentViaIntroduction', enrolmentViaIntroduction)
    campaignData.append('rewardNetCost', rewardNetCost)
    campaignData.append('rewardRRP', rewardRRP)
    campaignData.append('tedsPerStamp', tedsPerStamp)
    campaignData.append('tedsActive', tedsActive)

    if (formData.campaignType === 'Spend X Amount To Earn a Stamp') {
      campaignData.append('allowAccumulation', allowAccumulation)
      if (formData.allowAccumulation === true) {
        campaignData.append('resetAccumulationAfter', resetAccumulationAfter)
      }
    }

    if (formData.campaignType === 'Spend X Amount To Earn a Stamp' && spendPerStamp !== '') {
      campaignData.append('spendPerStamp', spendPerStamp)
    }

    // Append each rule to the FormData
    rules.forEach((rule, index) => {
      campaignData.append(`rules[${index}]`, rule)
    })

    if (image) {
      campaignData.append('image', image)
    }

    if (icon) {
      // if icon is a File object, append it as a file
      if (icon instanceof File) {
        campaignData.append('icon', icon, icon.name)
      } else {
        // otherwise, append it as a string
        campaignData.append('icon', icon)
      }
    }

    for (let pair of campaignData.entries()) {
      console.log(pair[0] + ', ' + pair[1])
    }

    for (let pair of campaignData.entries()) {
      console.log(pair[0] + ': ' + pair[1])
    }

    dispatch(updateCampaignDetails(campaignData))
  }

  // adds a new empty rule to the rules array
  const addRule = () => {
    setFormData({
      ...formData,
      rules: [...rules, ''],
    })
  }

  const removeRule = (index) => {
    const newRules = [...rules]
    newRules.splice(index, 1)
    setFormData({
      ...formData,
      rules: newRules,
    })
  }

  const handleCurrencyInputChange = (e) => {
    const { name, value } = e.target
    const formattedValue = value.replace(/[^0-9.]/g, '') // Remove non-numeric characters
    setFormData((prevState) => ({
      ...prevState,
      [name]: formattedValue,
    }))
  }

  // updates the rules array when a rule input is changed
  const handleRuleChange = (e, index) => {
    const newRules = [...formData.rules]
    newRules[index] = e.target.value
    setFormData((prevState) => ({ ...prevState, rules: newRules }))
  }

  const onFileChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      const url = URL.createObjectURL(file)
      setImageURL(url) // Update imageURL state
      setFormData((prevState) => ({
        ...prevState,
        image: file,
      }))
    } else {
      setImageURL(null) // Update imageURL state
      setFormData((prevState) => ({
        ...prevState,
        image: null,
      }))
    }
  }

  const handleDateChange = (date, field) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: date,
    }))
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <div className='flex'>
        <BackButton />
      </div>
      <div className='md:mx-20 pb-20'>
        <h1 className='text-3xl mb:text-4xl mb-8 font-bold font-mono'>Edit Campaign</h1>
        <form onSubmit={onSubmit}>
          <div className='flex flex-col md:flex-row'>
            <div className='flex flex-col md:w-1/2 w-full mr-3'>
              <div className='flex justify-between'>
                <label className='campaign-label'>Campaign Name</label>{' '}
                <div
                  className='campaign-tooltip'
                  data-tip='Enter a unique and catchy name for your loyalty campaign here. This is the first thing customers will see, so choose a name that reflects the spirit of your offer and aligns with your brand identity. Think of it as the title of your promise to your customers – make it enticing and clear.'>
                  <AiOutlineInfoCircle />
                </div>
              </div>
              <input
                type='text'
                className='w-full md:mr-3  text-base p-4 md:p-6 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light mb-3 bg-gray-200'
                placeholder='Enter Campaign Name'
                id='campaignName'
                name='campaignName'
                value={campaignName}
                onChange={onChange}
                required
                disabled
              />
              {formErrors.campaignName && <p className='text-red-500 text-sm'>{formErrors.campaignName}</p>}
            </div>
            {/* Reward Name*/}
            <div className='flex flex-col w-full md:w-1/2'>
              <div className='flex justify-between'>
                <label className='campaign-label'>Reward Name</label>
                <div
                  className='campaign-tooltip'
                  data-tip='Choose a reward name that will excite and motivate your customers to participate in your loyalty campaign. This is the "prize" they are working towards, so it should sound irresistible and valuable.'>
                  <AiOutlineInfoCircle />
                </div>
              </div>

              <input
                type='text'
                className='w-full  text-base p-4 md:p-6 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light mb-3'
                placeholder='Enter Reward Name (e.g. "Free Cup of Coffee)'
                id='rewardName'
                name='rewardName'
                value={rewardName}
                onChange={onChange}
              />
              {formErrors.rewardName && <p className='text-red-500 text-sm'>{formErrors.rewardName}</p>}
            </div>
          </div>
          <div className='flex flex-col'>
            {/* Campaign Type */}
            <div className='flex flex-col w-full mb-3'>
              <div className='flex justify-between'>
                <label className='campaign-label'>Campaign Type</label>
                <div
                  className='campaign-tooltip'
                  data-tip='Choose the type of campaign. "Buy X, Get Y Free (earn a stamp on each purchase)" rewards a stamp for each purchase. "Spend X Amount To Earn a Stamp" requires a specific spending amount to earn a stamp.'>
                  <AiOutlineInfoCircle />
                </div>
              </div>
              <select
                className='campaign-input bg-gray-200'
                id='campaignType'
                name='campaignType'
                value={formData.campaignType}
                onChange={onChange}
                disabled>
                <option value='Buy X, Get Y Free'>Buy X, Get Y Free (Earn a Stamp on Each Purchase)</option>
                <option value='Spend X Amount To Earn a Stamp'>Spend X Amount To Earn a Stamp</option>
              </select>
            </div>
            {/* Campaign Description */}
            <div className='flex justify-between'>
              <label className='campaign-label'>Campaign Description</label>
              <div
                className='campaign-tooltip'
                data-tip='Describe the essence of your loyalty campaign, focusing on what your customers can gain. Use this space to paint a picture of the value they’ll receive, like the joy of a complimentary coffee or the satisfaction of a free haircut after a set number of visits. Keep it enticing and succinct, allowing the rewards to take center stage.'>
                <AiOutlineInfoCircle />
              </div>
            </div>
            <textarea
              className='w-full md:mx-18 text-base p-4 md:p-6 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light mb-3'
              placeholder='Enter a campaign description'
              id='description'
              name='description'
              value={description}
              onChange={onChange}
            />
          </div>

          {/* Rules go here */}
          <div className='flex flex-col w-full mb-3'>
            <div className='flex justify-between items-center  mt-2'>
              <label className='campaign-label'>Rules</label>
              <div
                className='campaign-tooltip'
                data-tip='Establish clear rules for your campaign here. Define how customers can earn rewards and any conditions or limitations. For example, “One stamp per customer per visit”  Keep each rule straightforward to ensure customers understand how to participate.'>
                <AiOutlineInfoCircle />
              </div>
            </div>
            {rules &&
              rules.map((rule, index) => (
                <label key={index} className='input-group input-group-md w-full pb-4 relative'>
                  <span className='w-20'>Rule {index + 1}</span>
                  <input
                    key={index}
                    type='text'
                    value={rule}
                    onChange={(e) => handleRuleChange(e, index)}
                    className='input input-bordered input-md w-full'
                  />
                  <button type='button' className='absolute right-0 top-0 p-1 text-gray-600 hover:text-red-600' onClick={() => removeRule(index)}>
                    <AiOutlineCloseCircle />
                  </button>
                </label>
              ))}
            {/* Button to add rules */}
            <button type='button' onClick={addRule} className='btn flex w-full text-base'>
              Add campaign rule for customers
            </button>
          </div>

          <IconSelectEdit onIconChange={(icon) => setFormData({ ...formData, icon })} initialIcon={formData.icon} />

          {/* Start/ end date */}
          <div className='flex flex-col md:flex-row'>
            <div className='flex flex-col flex-auto md:mr-3'>
              <div className='flex justify-between items-center mb-1'>
                <label className='campaign-label' htmlFor='startDate'>
                  Start Date:
                </label>
                <div
                  className='campaign-tooltip'
                  data-tip='Choose the start date for your campaign. This is when your customers can start earning rewards, so set a date that aligns with your promotional strategy.'>
                  <AiOutlineInfoCircle className='text-md' />
                </div>
              </div>
              <DatePicker
                selected={startDate ? new Date(startDate) : null}
                onChange={(date) => handleDateChange(date, 'startDate')}
                className={`campaign-input appearance-none border border-gray-300 rounded-md w-full p-4 md:p-6 text-base md:text-lg focus:outline-none focus:ring-2 focus:ring-primary ${
                  !isStartDateEditable ? 'bg-gray-200' : 'bg-white'
                }`}
                dateFormat='yyyy-MM-dd'
                disabled={!isStartDateEditable}
              />
            </div>

            {includeEndDate && (
              <div className='flex flex-col flex-auto'>
                <div className='flex justify-between items-center mb-1'>
                  <label className='campaign-label' htmlFor='endDate'>
                    End Date:
                  </label>
                  <div
                    className='campaign-tooltip'
                    data-tip='Set the closing date for your campaign. This defines the timeframe in which customers can participate and complete their loyalty objectives.'>
                    <AiOutlineInfoCircle className='text-md' />
                  </div>
                </div>
                <DatePicker
                  selected={endDate ? new Date(endDate) : null}
                  onChange={(date) => handleDateChange(date, 'endDate')}
                  className='campaign-input bg-white appearance-none border border-gray-300 rounded-md w-full p-4 md:p-6 text-base md:text-lg focus:outline-none focus:ring-2 focus:ring-primary'
                  dateFormat='yyyy-MM-dd'
                  minDate={startDate ? new Date(startDate) : new Date()}
                />
              </div>
            )}
          </div>
          <div className='flex mb-3 mt-2'>
            <input
              type='checkbox'
              id='includeEndDate'
              name='includeEndDate'
              checked={includeEndDate}
              onChange={() => setIncludeEndDate(!includeEndDate)}
            />
            <label className='ml-2' htmlFor='includeEndDate'>
              Would you like to include an end date?
            </label>
          </div>

          {/* status/goal */}
          <div className='flex flex-col md:flex-row'>
            {/* Campaign Status */}
            <div className='flex flex-col flex-auto w-full'>
              <div className='flex justify-between'>
                <label className='campaign-label'>Campaign Status</label>{' '}
                <div
                  className='campaign-tooltip'
                  data-tip='This status indicates whether your campaign is currently active or paused. An active status means that customers can join and participate in the campaign. If paused, the campaign is temporarily inactive and will not be visible to customers. Use this feature to manage the visibility of your offers and control the flow of your loyalty program.'>
                  <AiOutlineInfoCircle />
                </div>
              </div>
              <select
                className='text-base p-4 md:p-6 font-light border border-gray-300 rounded-md flex-auto mb-2 md:mr-3 bg-white'
                id='status'
                name='status'
                value={status}
                onChange={onChange}
                required>
                <option value='active'>Active</option>
                <option value='legacy'>Paused</option>
              </select>
            </div>

            {/* Stamps to collect for goal*/}
            <div className='flex flex-col flex-auto w-full'>
              <div className='flex justify-between'>
                <label className='campaign-label'>Stamps to Collect for Goal</label>
                <div
                  className='campaign-tooltip'
                  data-tip='Specify the number of stamps a customer must collect to achieve the reward in this loyalty campaign. Set a goal that motivates customers to return and make repeated purchases. Consider your profit margins and customer behavior when determining this number. Make sure it`s attainable, yet profitable for your business.'>
                  <AiOutlineInfoCircle />
                </div>
              </div>
              <select
                className='text-base p-4 md:p-6 font-light border border-gray-300 rounded-md flex-auto mb-2 bg-gray-200'
                id='goal'
                name='goal'
                value={goal}
                onChange={onChange}
                required
                disabled>
                {[...Array(20)].map((_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/* Amount to Spend Per Stamp */}
          {formData.campaignType === 'Spend X Amount To Earn a Stamp' && (
            <>
              <div className='flex flex-col md:flex-row md:space-x-3'>
                {/* Amount to Spend Per Stamp */}
                <div className='flex flex-col w-full md:w-1/2 mb-3'>
                  <div className='flex justify-between'>
                    <label className='campaign-label'>Amount to Spend Per Stamp Rewarded</label>
                    <div
                      className='campaign-tooltip'
                      data-tip='Specify the amount a customer must spend to earn one stamp. This value sets the spending threshold for each stamp in your "Spend X Amount" campaign. For example, if set to $10, customers will earn one stamp for every $10 spent. Choose an amount that balances customer engagement with your business goals.'>
                      <AiOutlineInfoCircle />
                    </div>
                  </div>
                  <input
                    type='text'
                    className='campaign-input'
                    placeholder={`${user.currency} Enter Amount to Spend Per Stamp`}
                    id='spendPerStamp'
                    name='spendPerStamp'
                    value={`${user.currency} ${spendPerStamp}`}
                    onChange={handleCurrencyInputChange}
                    required
                  />
                  {formErrors.spendPerStamp && <p className='text-red-500 text-sm'>{formErrors.spendPerStamp}</p>}
                </div>

                {/* Allow accumulation across visits */}
                <div className='flex flex-col w-full md:w-1/2 mb-3'>
                  <div className='flex justify-between'>
                    <label className='campaign-label'>Allow accumulation across visits</label>
                    <div
                      className='campaign-tooltip'
                      data-tip='Choose whether to allow customers to accumulate spend across multiple visits. If set to "Yes", partial progress towards a stamp will be saved between visits. For example, if a stamp requires $10 and a customer spends $6, that progress will be saved for their next visit. If set to "No", customers must reach the required spend amount in a single visit to earn a stamp.'>
                      <AiOutlineInfoCircle />
                    </div>
                  </div>
                  <select
                    className='campaign-input'
                    id='allowAccumulation'
                    name='allowAccumulation'
                    value={formData.allowAccumulation}
                    onChange={onChange}>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                </div>
              </div>
              {/* Reset Accumulation After */}
              {formData.allowAccumulation === true && (
                <div className='flex flex-col w-full mb-3'>
                  <div className='flex justify-between'>
                    <label className='campaign-label'>Reset Accumulation After</label>
                    <div className='campaign-tooltip' data-tip='Set how long partial progress towards a stamp is saved.'>
                      <AiOutlineInfoCircle />
                    </div>
                  </div>
                  <input
                    type='range'
                    min='0'
                    max='60'
                    value={formData.resetAccumulationAfter === null ? 0 : formData.resetAccumulationAfter}
                    className='range range-xs'
                    id='resetAccumulationAfter'
                    name='resetAccumulationAfter'
                    onChange={onChange}
                  />
                  <div className='text-center mt-0 font-bold'>
                    {formData.resetAccumulationAfter === null || formData.resetAccumulationAfter === 0
                      ? 'Never'
                      : `${formData.resetAccumulationAfter} days`}
                  </div>
                </div>
              )}
            </>
          )}

          <div className='divider'></div>

          {/* Net Cost + RRP */}
          <div className='flex flex-col md:flex-row'>
            <div className='flex flex-col flex-auto w-full'>
              <div className='flex justify-between'>
                <label className='campaign-label'>Reward Net Cost (excluding VAT)</label>{' '}
                <div
                  className='campaign-tooltip'
                  data-tip='Input the actual cost to your business for providing the reward. This figure is for your internal accounting and helps you track the cost-effectiveness of your campaign. It will not be visible to customers, but it`s crucial for you to understand the economics of your loyalty program.'>
                  <AiOutlineInfoCircle />
                </div>
              </div>
              <input
                type='text'
                className='w-50 text-base md:text-base p-4 md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3 md:mr-3 bg-gray-200'
                placeholder='Enter Reward Net Cost (excluding VAT)'
                id='rewardNetCost'
                name='rewardNetCost'
                value={` ${user.currency} ${rewardNetCost}`}
                onChange={handleCurrencyInputChange}
                disabled
              />
            </div>
            <div className='flex flex-col flex-auto w-full'>
              <div className='flex justify-between'>
                <label className='campaign-label'>Reward RRP (including VAT)</label>
                <div
                  className='campaign-tooltip'
                  data-tip='Enter the Recommended Retail Price (RRP) of the reward. This is the price customers would typically pay if they were to purchase the reward outright. This information can be useful for marketing purposes to highlight the value of the loyalty reward to customers and to emphasize the savings they are making by participating in your campaign.'>
                  <AiOutlineInfoCircle />
                </div>
              </div>
              <input
                type='text'
                className='w-50 text-base p-4 md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3 md:mr-3 bg-gray-200 '
                placeholder={`${user.currency} Enter Reward RRP (including VAT)`}
                id='rewardRRP'
                name='rewardRRP'
                value={`${user.currency} ${rewardRRP}`}
                onChange={handleCurrencyInputChange}
                disabled
              />
            </div>
          </div>
          <div className='divider'></div>
          {/* Allow TEDS + tedsPerStamp*/}
          <div className='flex flex-col md:flex-row'>
            {/* Participate in TED Trading System
            <div className='flex flex-col flex-auto w-full md:mr-3  md:mt-0 mb-3'>
              <div className='flex justify-between'>
                <label className='campaign-label'>Participate in TED Trading System</label>
                <div className='campaign-tooltip' data-tip='Choose whether to allow TEDs trading for this campaign.'>
                  <AiOutlineInfoCircle />
                </div>
              </div>
              <select className='campaign-input' value={formData.tedsActive.toString()} id='tedsActive' name='tedsActive' onChange={onChange}>
                <option value='true'>Allow</option>
                <option value='false'>Disallow</option>
              </select>
            </div> */}

            {/* TEDS Per Stamp */}
            {formData.tedsActive && (
              <div className='flex flex-col flex-auto w-full'>
                <div className='flex justify-between'>
                  <label className='campaign-label'>TEDS Per Stamp</label>
                  <div
                    className='campaign-tooltip'
                    data-tip={`This field displays the TEDS value automatically assigned to each stamp. It's calculated based on the Reward RRP and the number of stamps required to earn the reward. The calculation is: (Reward RRP * 10) / Number of Stamps. For example, if the Reward RRP is ${userDetails?.currencySymbol}20.00 (equivalent to 200 TEDS) and 10 stamps are required, the TEDS Per Stamp value would be 20 (200 / 10). This value updates automatically when you change either the Reward RRP or the number of stamps. Feel free to change this value to match your business needs.`}>
                    <AiOutlineInfoCircle />
                  </div>
                </div>
                <input
                  type='text'
                  className='campaign-input bg-gray-200'
                  placeholder='Enter TEDS Per Stamp'
                  id='tedsPerStamp'
                  name='tedsPerStamp'
                  value={formData.tedsPerStamp || ''}
                  onChange={onChange}
                  disabled
                />
              </div>
            )}
          </div>
          <div className='divider'></div>
          {/* server record order /compulsory */}
          <div className='flex flex-col md:flex-row'>
            {/* Allow recording value of order */}
            <div className='flex flex-col mr-3 flex-auto w-full'>
              <div className='flex justify-between'>
                <label className='campaign-label'>Record Order Value</label>{' '}
                <div
                  className='campaign-tooltip'
                  data-tip='Toggle this option to enable servers to input the value of the order when scanning a customers QR code. This feature provides valuable insights into customer spending patterns and can be essential for accurate accounting and financial analysis. By tracking transaction values, you gain data to optimize your loyalty campaigns and tailor them to customer behavior.'>
                  <AiOutlineInfoCircle />
                </div>
              </div>
              <select
                className={`campaign-input mr-3 mb-2 ${formData.campaignType === 'Spend X Amount To Earn a Stamp' ? 'bg-gray-300' : ''}`}
                id='value'
                name='value'
                value={value}
                onChange={(e) => {
                  onChange(e)
                  if (e.target.value === 'false') {
                    setFormData((prevState) => ({
                      ...prevState,
                      compulsory: 'false',
                    }))
                  }
                }}
                required
                disabled={formData.campaignType === 'Spend X Amount To Earn a Stamp'}>
                <option value='true'>Yes</option>
                <option value='false'>No</option>
              </select>
            </div>
            {/* Compulsory yes/no*/}
            {value.toString() === 'true' && (
              <div className='flex flex-col flex-auto w-full'>
                <div className='flex justify-between'>
                  <label className='campaign-label'>Compulsory Order Value Recording</label>
                  <div
                    className='campaign-tooltip'
                    data-tip='Set this to "Yes" to mandate servers to enter the order value each time a customer`s QR code is scanned. This ensures consistent data collection across all transactions, which is crucial for thorough financial tracking, analyzing customer spend trends, and enhancing the accuracy of your loyalty program`s success metrics.'>
                    <AiOutlineInfoCircle />
                  </div>
                </div>
                <select
                  className={`campaign-input ${formData.campaignType === 'Spend X Amount To Earn a Stamp' ? 'bg-gray-300' : ''}`}
                  id='compulsory'
                  name='compulsory'
                  value={compulsory}
                  onChange={onChange}
                  required
                  disabled={formData.campaignType === 'Spend X Amount To Earn a Stamp'}>
                  <option value='true'>Yes</option>
                  <option value='false'>No</option>
                </select>
              </div>
            )}
          </div>
          <div className='divider'></div>

          {/* reward stamps */}
          <div className='flex flex-col md:flex-row'>
            {/* Stamps to referrer */}
            <div className='flex flex-col flex-auto w-full'>
              <div className='flex justify-between'>
                <label className='campaign-label'>Referrer Reward Stamps</label>{' '}
                <div
                  className='campaign-tooltip'
                  data-tip='Determine how many stamps the referrer will receive when their referred friend earns their first stamp. This incentive encourages your current customers to bring in new patrons and helps to expand your customer base. Set this reward carefully to ensure it is enticing enough to motivate referrals, while also maintaining the profitability of your campaign.'>
                  <AiOutlineInfoCircle />
                </div>
              </div>
              <select
                className='bg-white w-50 text-base p-4 md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light md:mr-3 mb-2'
                id='referrerStampsRewarded'
                name='referrerStampsRewarded'
                value={referrerStampsRewarded}
                onChange={onChange}
                required>
                <option value='0'>Don't reward any stamps</option>
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='3'>3</option>
                <option value='4'>4</option>
                <option value='5'>5</option>
              </select>
            </div>
            {/* Stamps to friend */}
            <div className='flex flex-col flex-auto w-full'>
              <div className='flex justify-between'>
                <label className='campaign-label'>New Customer Reward Stamps</label>{' '}
                <div
                  className='campaign-tooltip'
                  data-tip='Choose the number of bonus stamps a newly referred customer will receive on earning their first stamp. This welcome reward acts as a powerful incentive for new customers to start engaging with your loyalty program. It’s an effective way to thank them for joining through a referral and can significantly boost the early experience with your brand.'>
                  <AiOutlineInfoCircle />
                </div>
              </div>
              <select
                className='bg-white w-50 text-base  p-4 md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light md:mr-3 mb-2'
                id='referredFriendStampsRewarded'
                name='referredFriendStampsRewarded'
                value={referredFriendStampsRewarded}
                onChange={onChange}
                required>
                <option value='0'>Don't reward any stamps</option>
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='3'>3</option>
                <option value='4'>4</option>
                <option value='5'>5</option>
              </select>
            </div>
          </div>
          <div className='divider'></div>
          {/* Enrolment */}
          <div className='flex flex-col md:flex-row'>
            {/* Enrolment Via QR */}
            <div className='flex flex-col flex-auto w-full'>
              <div className='flex justify-between'>
                <label className='campaign-label'>Enrollment via QR Code</label>{' '}
                <div
                  className='campaign-tooltip'
                  data-tip='Specify whether customers can enroll in this campaign by scanning the QR code. "Allowed" means the campaign will be visible and joinable through QR code scanning, facilitating easy access for customers. "Not Allowed" restricts enrollment to other methods, which can be useful for exclusive or targeted offers.'>
                  <AiOutlineInfoCircle />
                </div>
              </div>
              <select
                className='bg-white w-50 text-base p-4 md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light md:mr-3 mb-2'
                id='enrolmentViaQr'
                name='enrolmentViaQr'
                value={enrolmentViaQr}
                onChange={onChange}
                required>
                <option value='true'>Allowed</option>
                <option value='false'>Not allowed</option>
              </select>
            </div>
            {/* Enrolment Via Introduction */}
            <div className='flex flex-col flex-auto w-full mb-6'>
              <div className='flex justify-between'>
                <label className='campaign-label'>Enrollment Via Introduction</label>{' '}
                <div
                  className='campaign-tooltip'
                  data-tip='Enable this to allow "Enrollment Via Introduction", where customers can be added to this campaign through a referral or directly by a partner in-store. When "Allowed", it fosters growth through the refer-a-friend system and personal in-shop introductions, leveraging word-of-mouth and direct interactions for campaign expansion.'>
                  <AiOutlineInfoCircle />
                </div>
              </div>
              <select
                className='bg-white w-50 text-base p-4 md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light md:mr-3 mb-2'
                id='enrolmentViaIntroduction'
                name='enrolmentViaIntroduction'
                value={enrolmentViaIntroduction}
                onChange={onChange}
                required>
                <option value='true'>Allowed</option>
                <option value='false'>Not allowed</option>
              </select>
            </div>
          </div>

          {/* file upload */}

          <div className='flex justify-center items-center'>
            <div className='mr-8 w-full'>
              <h1 className='flex mb-2 text-base'> Choose an Image for Your Reward (optimal aspect ratio is 1:1, such as 300px/300px)</h1>
              <input
                type='file'
                className='w-full text-base p-4 md:p-6 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light mb-3'
                id='image'
                name='image'
                onChange={onFileChange}
                accept='image/*'
              />
            </div>
            <div className='w-full'>
              {(imageURL || images?.image?.image) && (
                <div className='flex flex-col items-center'>
                  <h2 className='font-bold mt-4 m-3 text-base'>Reward Image Preview:</h2>
                  <img
                    src={images?.image?.image || imageURL}
                    className='flex justify-center items-center rounded-md'
                    alt='selected icon'
                    width='100'
                    height='100'
                  />
                </div>
              )}
            </div>
          </div>

          {/* Add Campaign Button */}
          <div className='flex flex-col space-y-6 md:flex-row justify-between items-center py-6 md:space-y-0 md:space-x-6'>
            <button className='flex p-4 md:p-6 btn-primary text-white w-full md:w-auto font-bold justify-center items-center space-x-4 font-sans rounded-md shadow-lg px-9 shadow-violet-100 hover:bg-opacity-90  hover:shadow-lg border transition hover:-translate-y-0.5 duration-150'>
              <span>Update Campaign</span>
              <img src='images/next-arrow.svg' alt='' />
            </button>
          </div>
          {Object.keys(formErrors).length > 0 && (
            <p className='text-red-500 text-lg text-left font-bold mb-10'>
              The campaign is missing some details, please review and fix the highlighted fields to successfully update the campaign.
            </p>
          )}
        </form>
      </div>
    </>
  )
}

export default EditCampaign
