import api from '../../api'
import axios from 'axios'
const { REACT_APP_SERVER_URL } = process.env
const SUBSCRIPTION_URL = `${REACT_APP_SERVER_URL}/api/subscription`
const API_URL = `${REACT_APP_SERVER_URL}/api/clients`
const API_IMAGE_URL = `${REACT_APP_SERVER_URL}/api/images`

// *Register a new user
const register = async (userData) => {
  console.log(userData, userData)
  const response = await axios.post(API_URL, userData)

  if (response.data) {
    localStorage.setItem('regDetails', JSON.stringify(response.data))
  }
  return response.data
}

// *login a new user
const login = async (userData) => {
  const response = await axios.post(API_URL + '/login', userData)

  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data))
  }
  return response.data
}

// *update a clients user details
const updateUserDetails = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await api.post(API_URL + '/update-user', userData, config)

  return response.data
}

// *update a servers user details
const updateServerDetails = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await api.post(API_URL + '/update-server-user', userData, config)

  return response.data
}

// *get user details
const getUserDetails = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.get(`${API_URL}/get-user-details`, config)

    return response.data
  } catch (error) {
    throw error
  }
}

// * update social details
const updateSocialLinks = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await api.post(API_URL + '/update-social', userData, config)

  return response.data
}

// *get social details
const getSocialLinks = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.get(`${API_URL}/get-social`, config)

    return response.data
  } catch (error) {
    throw error
  }
}

// * update location details
const updateLocationInfo = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await api.put(API_URL + '/update-location', userData, config)

  return response.data
}

// *get location details
const getLocationDetails = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.get(`${API_URL}/get-location`, config)

    return response.data
  } catch (error) {
    throw error
  }
}

// * check post code status
const checkPostCodeStatus = async (postcodeData) => {
  const response = await api.post(SUBSCRIPTION_URL + '/check-postcode', postcodeData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data
}

const fetchImage = async (id) => {
  try {
    const response = await fetch(`${API_IMAGE_URL}/image/${id}`)
    if (!response.ok) {
      throw new Error(`Server returned a ${response.status} status code`)
    }
    const blob = await response.blob()
    const imageSrc = URL.createObjectURL(blob)
    return imageSrc
  } catch (error) {
    console.error(`An error occurred while fetching the image: ${error}`)
    throw error
  }
}

// Initiate password reset
const resetPassword = async (emailAddress) => {
  const response = await axios.post(`${API_URL}/forgot-password`, { email: emailAddress })
  return response.data
}

const updateUserPassword = async (userData, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
    const response = await api.put(`${API_URL}/update-user-password`, userData, config)
    return response.data
  } catch (error) {
    console.error(`An error occurred while updating the user password: ${error}`)
    throw error
  }
}

const updateQrCode = async (formData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await api.put(API_URL + '/update-qr-code', formData, config)

  return response.data
}

// *get customers ID via shortcode
const getCustomerID = async (shortCode, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await api.get(`${API_URL}/get-customer-id/${shortCode}`, config)

  return response.data
}

// Auth check
const authCheck = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.get(`${API_URL}/auth-check`, config)
    return response.data
  } catch (error) {
    throw error
  }
}

// Check payment status
const checkPaymentStatus = async (clientID) => {
  try {
    const response = await api.get(`${SUBSCRIPTION_URL}/check-payment-status/${clientID}`)

    return response
  } catch (error) {
    throw error
  }
}

// *get location details
const getPostcodeSectors = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.get(`${REACT_APP_SERVER_URL}/api/subscription/get-postcode-sectors`, config)

    return response.data
  } catch (error) {
    throw error
  }
}

// *get location details
const updatePostcodeSectors = async (token, userData) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.put(`${REACT_APP_SERVER_URL}/api/subscription/update-postcode-sectors`, userData, config)

    return response.data
  } catch (error) {
    throw error
  }
}

const getIntroducedClients = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(`${API_URL}/get-referred-partners`, config)
  return response.data
}

// logout a new user
const logout = () => localStorage.removeItem('user')

const updateSecurityCode = async (securityData, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    // Separate API endpoint for checking existing code
    const response = await api.post(
      API_URL + '/security-code',
      {
        currentCode: securityData.currentCode || null,
        newCode: securityData.newCode,
      },
      config
    )

    return response.data
  } catch (error) {
    // Ensure we're passing the error message from the server
    if (error.response?.data?.message) {
      throw new Error(error.response.data.message)
    }
    throw error
  }
}

const getSecurityCodeStatus = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.get(API_URL + '/security-code-status', config)
    return response.data
  } catch (error) {
    if (error.response?.data?.message) {
      throw new Error(error.response.data.message)
    }
    throw error
  }
}

const verifySecurityCode = async (code, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const response = await api.post(API_URL + '/verify-security-code', { code }, config)

    return response.data
  } catch (error) {
    if (error.response?.data?.message) {
      throw new Error(error.response.data.message)
    }
    throw error
  }
}

const checkAccountStatus = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.get(`${API_URL}/status`, config)
    return response.data
  } catch (error) {
    throw error
  }
}

const authService = {
  register,
  login,
  logout,
  resetPassword,
  updateUserDetails,
  updateServerDetails,
  getUserDetails,
  updateSocialLinks,
  getSocialLinks,
  fetchImage,
  checkPostCodeStatus,
  updateUserPassword,
  updateQrCode,
  getCustomerID,
  getLocationDetails,
  updateLocationInfo,
  authCheck,
  checkPaymentStatus,
  getPostcodeSectors,
  updatePostcodeSectors,
  getIntroducedClients,
  updateSecurityCode,
  getSecurityCodeStatus,
  verifySecurityCode,
  checkAccountStatus,
}

export default authService
