import React from 'react'

const AutoFillButton = ({ onAutoFill }) => {
  const handleClick = () => {
    const testData = {
      businessName: 'Wizard Coffee',
      name: 'John Doe',
      email: 'ucancall@outlook.com',
      password: 'cycroteX6950',
      password2: 'cycroteX6950',
      accessPass: '123456',
      postcode: 'SW1A 1AA',
      businessType: 'Thai',
      businessCategory: 'Restaurants/Cafes',
      country: 'GB',
      agentReferralCode: '',
    }

    onAutoFill(testData)
  }

  return (
    <button type='button' onClick={handleClick} className='btn btn-secondary mb-4'>
      Auto-fill Form
    </button>
  )
}

export default AutoFillButton
