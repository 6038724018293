import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IconContext } from 'react-icons'
import { Helmet } from 'react-helmet-async'
import { FiMail, FiFacebook, FiSend, FiCopy, FiLinkedin } from 'react-icons/fi'
import { FaXTwitter } from 'react-icons/fa6'
import { PiTelegramLogo } from 'react-icons/pi'
import { FaWhatsapp } from 'react-icons/fa'
import IntroducedClients from '../components/IntroducedClients'

const PartnerReferBusiness = () => {
  const { user } = useSelector((state) => state.auth)
  const [copied, setCopied] = useState(false)
  const [referralCode, setReferralCode] = useState('')
  const [referralLink, setReferralLink] = useState('')
  const [codeCopied, setCodeCopied] = useState(false)
  const [linkCopied, setLinkCopied] = useState(false)

  useEffect(() => {
    if (user && user.referralCode) {
      setReferralCode(user.referralCode)
      setReferralLink(`https://www.loyaltyclubplc.com/partners?ref=${user.referralCode}`)
    }
  }, [user])

  const referMessages = {
    email: `Hey there!
    
    I've discovered a game-changing platform for business growth and customer retention: Loyalty Club PLC. I think it could be a perfect fit for your business too.
    
    Here's why you should consider joining:
    
    • Skyrocket customer loyalty with customizable digital stamp cards
    • Boost repeat business and maximize customer lifetime value
    • Effortlessly manage your loyalty program with our user-friendly platform
    • Reach customers directly with targeted messages and promotions sent straight to their devices
    • Expand your customer base through our interactive map, making your business easily discoverable to potential customers
    • Leverage the power of word-of-mouth marketing with our reward-based referral system, turning your satisfied customers into your best marketers
    • Tap into the TEDS digital currency ecosystem, fostering growth through partnerships with businesses across our network
    • Gain invaluable insights into customer behavior to refine your strategies and offerings
    
    By using my referral code ${referralCode}, you'll get instant access to all these fantastic features. Plus, you'll be helping me earn 500 TEDS (worth £50) - it's a win-win!
    
    Sign up here: ${referralLink}
    
    I'd be happy to chat more about my experience with the platform. Feel free to reach out if you have any questions!
    
    Best regards,
    ${user ? user.name : ''}`,

    whatsapp: `🚀 Revolutionize Your Business with Loyalty Club PLC! 🌟
    
    I've found an incredible platform that's transforming how businesses engage with customers. Here's why you should join:
    
    ✨ Key Benefits:
    • Boost loyalty with custom digital stamp cards
    • Increase customer lifetime value
    • Send targeted promotions directly to customer devices
    • Get discovered easily with our interactive business map
    • Grow through customer referrals - they market for you!
    • Join the TEDS digital currency network for inter-business growth
    • Gain deep insights into customer behavior
    
    Join using my code ${referralCode} and help me earn 500 TEDS (£50 value)! It's a win-win!
    
    Sign up here: ${referralLink}
    
    Questions? I'd love to chat about my experience!`,

    twitter: `🔥 Transform your business with @LoyaltyClubPLC! 
    
    • Boost loyalty with digital stamps
    • Direct customer messaging
    • Get discovered on our map
    • Grow through customer referrals
    • Join TEDS currency network
    • Gain valuable customer insights
    
    Use my code ${referralCode} - I'll earn 500 TEDS! 
    
    Join now: ${referralLink}
    
    #BusinessGrowth #CustomerLoyalty`,

    linkedin: `Attention Business Owners and Entrepreneurs!
    
    I've discovered a powerful tool that's revolutionizing customer engagement and business growth: Loyalty Club PLC.
    
    🌟 Here's why you need to join:
    
    1. Skyrocket Customer Loyalty: Create customizable digital stamp cards that keep customers coming back.
    
    2. Maximize Customer Lifetime Value: Turn one-time buyers into lifelong fans.
    
    3. Direct Customer Communication: Send targeted messages and promotions straight to your customers' devices.
    
    4. Expand Your Reach: Get discovered by potential customers through our interactive business map.
    
    5. Harness Word-of-mouth Power: Our reward-based referral system turns your satisfied customers into your best marketers.
    
    6. Join a Growth Network: Tap into the TEDS digital currency ecosystem, fostering partnerships and growth across our business network.
    
    7. Data-Driven Decisions: Gain invaluable insights into customer behavior to refine your strategies and offerings.
    
    8. User-Friendly Platform: Effortlessly manage your loyalty program with our intuitive interface.
    
    By signing up with my referral code, you'll unlock all these game-changing features, and you'll help me earn 500 TEDS (£50 value). It's a win-win!
    
    My referral code: ${referralCode}
    
    Join the loyalty revolution: ${referralLink}
    
    Let's elevate our businesses together! Feel free to reach out if you want to discuss how Loyalty Club PLC has transformed my business.`,

    telegram: `🚀 Supercharge Your Business with Loyalty Club PLC! 🌟
    
    I've found a game-changing platform for business growth and customer retention. Here's why you should join:
    
    ✨ Exclusive Benefits:
    • Boost loyalty with custom digital stamp cards
    • Maximize customer lifetime value
    • Send targeted promos directly to customers
    • Get discovered via our interactive business map
    • Grow through customer referrals - they become your marketers!
    • Join the TEDS digital currency network for inter-business growth
    • Gain deep insights into customer behavior
    • User-friendly platform for easy management
    
    🎁 Use my code ${referralCode} to unlock all features. I'll earn 500 TEDS (£50). It's a win-win!
    
    Join now: ${referralLink}
    
    Questions? I'd love to share my experience with you!`,
  }

  // Set timeout for display of copied message
  useEffect(() => {
    let timeoutId
    if (copied) {
      timeoutId = setTimeout(() => {
        setCopied(false)
      }, 1400)
    }
    return () => clearTimeout(timeoutId)
  }, [copied])

  const handleEmailInvite = () => {
    const emailBody = encodeURIComponent(referMessages.email)
    const mailtoLink = `mailto:?subject=Join Loyalty Club PLC and transform your business!&body=${emailBody}`
    window.open(mailtoLink, '_self')
  }

  const handleFacebookInvite = () => {
    const encodedLink = encodeURIComponent(referralLink)
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`, '_blank')
  }

  const handleWhatsappInvite = () => {
    const whatsappMessage = encodeURIComponent(referMessages.whatsapp)
    window.open(`https://api.whatsapp.com/send?text=${whatsappMessage}`)
  }

  const handleTwitterInvite = () => {
    const twitterMessage = encodeURIComponent(referMessages.twitter)
    window.open(`https://twitter.com/intent/tweet?text=${twitterMessage}`)
  }

  const handleLinkedInInvite = () => {
    const linkedInMessage = encodeURIComponent(referMessages.linkedin)
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${referralLink}&summary=${linkedInMessage}`)
  }

  const handleTelegramInvite = () => {
    const telegramMessage = encodeURIComponent(referMessages.telegram)
    window.open(`https://t.me/share/url?url=${referralLink}&text=${telegramMessage}`)
  }

  const handleReferralLinkClick = () => {
    navigator.clipboard.writeText(referralLink)
    setCopied(true)
  }

  const handleCopyCode = () => {
    navigator.clipboard.writeText(referralCode)
    setCodeCopied(true)
    setTimeout(() => setCodeCopied(false), 1400)
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(referralLink)
    setLinkCopied(true)
    setTimeout(() => setLinkCopied(false), 1400)
  }

  return (
    <>
      <Helmet>
        <title>Refer a Business | Loyalty Club PLC</title>
      </Helmet>

      <div className='flex flex-col items-center space-y-4'>
        <h1 className='text-3xl font-bold text-gray-700 mb-4'>Refer a Business</h1>
        <p className='m-6 pb-7 text-xs md:text-base'>
          Spread the word about Loyalty Club PLC and earn rewards! For each successful referral, you'll receive 250 TEDS (worth £25). Help fellow
          businesses boost their customer loyalty while growing your TEDS balance. Start referring now!
        </p>

        <div className='grid grid-cols-4 gap-4 md:flex md:space-x-7'>
          <IconContext.Provider value={{ className: 'text-blue-500 cursor-pointer hover:text-blue-700' }}>
            <div onClick={handleEmailInvite}>
              <FiMail size={36} />
            </div>
            <div onClick={handleFacebookInvite}>
              <FiFacebook size={36} />
            </div>
            <div onClick={handleTelegramInvite}>
              <PiTelegramLogo size={36} />
            </div>
            <div onClick={handleTwitterInvite}>
              <FaXTwitter size={36} />
            </div>
            <div onClick={handleLinkedInInvite}>
              <FiLinkedin size={36} />
            </div>
            <div onClick={handleWhatsappInvite}>
              <FaWhatsapp size={36} />
            </div>
            <div onClick={handleCopyLink}>
              <FiCopy size={36} />
            </div>
          </IconContext.Provider>
        </div>

        {/* New section to display referral code and link */}
        <div className='bg-orange-200 p-4  rounded-lg shadow-md w-full max-w-md'>
          <h2 className='text-xl font-semibold  mb-2'>Your Referral Information</h2>
          <div className='space-y-4'>
            <div className='bg-white rounded p-2'>
              <label className='block text-sm  text-gray-700 mb-1'>Referral Code</label>
              <div className='flex items-center justify-between'>
                <span className='text-lg font-mono font-bold'>{referralCode}</span>
                <button onClick={handleCopyCode} className='btn btn-primary'>
                  {codeCopied ? 'Copied!' : 'Copy'}
                </button>
              </div>
            </div>
            <div className='bg-white rounded p-2'>
              <label className='block text-sm font-medium text-gray-700 mb-1'>Referral Link</label>
              <div className='flex items-center justify-between'>
                <span className='text-sm font-mono truncate mr-2'>{referralLink}</span>
                <button onClick={handleCopyLink} className='btn btn-primary'>
                  {linkCopied ? 'Copied!' : 'Copy'}
                </button>
              </div>
            </div>
          </div>
        </div>
        <IntroducedClients />
      </div>
    </>
  )
}

export default PartnerReferBusiness
