import { useState, useEffect } from 'react'
import toast from 'react-hot-toast'
import { useSelector, useDispatch } from 'react-redux'
import { login, reset } from '../features/auth/authSlice'
import { useNavigate, Link } from 'react-router-dom'
import Spinner from '../components/Spinner'

function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })

  const { email, password } = formData

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth)

  useEffect(() => {
    if (isError) {
      console.log(message.message)
      toast.error(message.message)
    }

    // Redirect when logged in
    if (isSuccess || user) {
      navigate('/')
    }

    // Only reset if it's not the inactive subscription message
    if (message.message !== 'Your subscription is inactive. Please contact support if you wish to recover your account') {
      dispatch(reset())
    }
  }, [isError, isSuccess, user, message, navigate, dispatch])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...formData,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const userData = {
      email,
      password,
    }

    dispatch(login(userData))
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      {/* Global Container */}
      <div className='flex items-center justify-center'>
        {/* Card Container */}
        <div className='relative flex flex-col m-3 space-y-10 bg-white shadow-2xl rounded-2xl md:flex-row md:space-y-0 md:m-0 w-full md:w-auto'>
          {/* Left Side */}
          <div className='p-6 md:p-20'>
            {/* Top Content */}
            <h2 className='font-mono mb-5 text-3xl md:text-4xl font-bold text-left flex'>Log in</h2>
            <p className='max-w-sm mb-12 text-xs md:text-base font-sans font-light text-gray-600 text-left'>Please log in to access your account</p>
            {isError && message.message === 'Your subscription is inactive. Please contact support if you wish to recover your account' && (
              <div className='alert alert-error mb-6'>
                <svg xmlns='http://www.w3.org/2000/svg' className='stroke-current shrink-0 h-6 w-6' fill='none' viewBox='0 0 24 24'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z'
                  />
                </svg>
                <span>{message.message}</span>
              </div>
            )}
            <form onSubmit={onSubmit}>
              <div className='form-group relative mb-2'>
                <input type='text' className='global-input peer' placeholder=' ' id='email' name='email' value={email} onChange={onChange} required />
                <label
                  htmlFor='email'
                  className='global-form-label transition-all duration-200 peer-focus:top-0 peer-focus:-translate-y-0 peer-focus:text-sm peer-focus:pt-1 peer-[:not(:placeholder-shown)]:top-0 peer-[:not(:placeholder-shown)]:-translate-y-0 peer-[:not(:placeholder-shown)]:text-sm peer-[:not(:placeholder-shown)]:pt-1'>
                  Email Address
                </label>
              </div>
              <div className='form-group relative mb-2'>
                <input
                  type='password'
                  className='global-input peer'
                  placeholder=' '
                  id='password'
                  name='password'
                  value={password}
                  onChange={onChange}
                  required
                />
                <label
                  htmlFor='password'
                  className='global-form-label transition-all duration-200 peer-focus:top-0 peer-focus:-translate-y-0 peer-focus:text-sm peer-focus:pt-1 peer-[:not(:placeholder-shown)]:top-0 peer-[:not(:placeholder-shown)]:-translate-y-0 peer-[:not(:placeholder-shown)]:text-sm peer-[:not(:placeholder-shown)]:pt-1'>
                  Password
                </label>
              </div>

              {/* Middle Content */}
              <div className='flex flex-col space-y-6 md:flex-row justify-between items-center py-6 md:space-y-0 md:space-x-6 text-xs md:text-base'>
                <Link to='/resetpassword' className='font-thin text-cyan-700'>
                  Forgot password?
                </Link>
                <button className='flex p-4 md:p-6 btn-primary text-white w-full md:w-auto font-bold justify-center items-center space-x-4 font-sans rounded-md shadow-lg px-9 shadow-violet-100 hover:bg-opacity-90 hover:shadow-lg border transition hover:-translate-y-0.5 duration-150'>
                  <span>Submit</span>
                  <img src='images/next-arrow.svg' alt='' />
                </button>
              </div>
            </form>
          </div>
          {/* Right Side */}
          <img src='images/treasure.png' alt='' className='w-[430px] hidden md:block rounded-tr-lg rounded-br-lg' />
        </div>
      </div>
    </>
  )
}

export default Login
